import { documentPath, imagePath } from 'utils/path/path-utils';

export const SITENAME = 'Fairstone Bank Online Application';
export const SITECREATOR = 'Fairstone Bank';
export const SITEURL = 'https://www.walmartrewards.ca';

export enum SEOPaths {
    SPLASH = 'splash',
    NOT_FOUND = 'not_found',
    APPLY = 'apply',
}

export const enBankOrCreditUnionLd = {
    address: {
        addressCountry: `Canada`,
        addressLocality: 'Toronto',
        addressRegion: 'ON',
        postalCode: 'M5E1G4',
        streetAddress: '33 Yonge Street, Suite 810',
        type: `PostalAddress`,
    },
    addressOneLine: '33 Yonge Street, Suite 810, Toronto, ON, M5E1G4',
    Brand: ['Walmart Rewards', 'Walmart', 'Mastercard'],
    logo: imagePath('logo.svg', 'en'),
    name: 'Fairstone Bank of Canada',
    telephone: '1-888-331-6133',
    URL: 'https://www.walmartrewards.ca',
};

export const enRewardsMCLoanOrCreditLd = {
    currency: 'CAD',
    description:
        'Walmart Rewards™ Mastercard® is a no annual fee credit card that allows you to earn Walmart Reward Dollars every time you use your card.',
    gracePeriod: '21 days',
    image: imagePath('MC.png', 'en'),
    interestRate: `Residents Outside Quebec:
    Preferred Rate Card
    21.89%* is the Interest Rate.
    22.97%* is the Cash Interest Rate.

    For Quebec Residents:
    Preferred Rate Card
    21.89%* is the Interest Rate.
    21.97%* is the Cash Interest Rate.

    Standard Rate Card‡
    25.99%* is the Interest Rate.
    27.99%* is the Cash Interest Rate.

    You are applying for our Preferred Rate Card. If you are not approved for the Preferred Rate Card, you acknowledge that you are also applying for the Standard Rate Card and you consent to having your application considered for both Cards.
    ‡ The Standard Rate Card is currently not available to Quebec residents and applications will only be processed in relation to the Preferred Rate Card for Quebec residents.
    `,
    loanType: 'Credit Card',
    productName: `Walmart Rewards™ Mastercard®
    Walmart Rewards™ World Mastercard™
    `,
    termsOfService: documentPath('Online_Cardholder_Agreement_EN_VerT_AU24.pdf', 'en'),
    url: 'https://www.walmartrewards.ca/apply/',
};

export const enWorldMCLoanOrCreditLd = {
    currency: 'CAD',
    description:
        'Walmart Rewards™ Mastercard® is a no annual fee credit card that allows you to earn Walmart Reward Dollars every time you use your card.',
    gracePeriod: '21 days',
    image: imagePath('MCWorld.png', 'en'),
    interestRate: `Residents Outside Quebec:
    Preferred Rate Card
    21.89%* is the Interest Rate.
    22.97%* is the Cash Interest Rate.

    For Quebec Residents:
    Preferred Rate Card
    21.89%* is the Interest Rate.
    21.97%* is the Cash Interest Rate.

    Standard Rate Card‡
    25.99%* is the Interest Rate.
    27.99%* is the Cash Interest Rate.
    
    You are applying for our Preferred Rate Card. If you are not approved for the Preferred Rate Card, you acknowledge that you are also applying for the Standard Rate Card and you consent to having your application considered for both Cards.
    ‡ The Standard Rate Card is currently not available to Quebec residents and applications will only be processed in relation to the Preferred Rate Card for Quebec residents.
    `,
    loanType: 'Credit Card',
    productName: `Walmart Rewards™ World Mastercard™
    `,
    termsOfService: documentPath('Online_Cardholder_Agreement_EN_VerT_AU24.pdf', 'en'),
    url: 'https://www.walmartrewards.ca/apply/',
};

export const frBankOrCreditUnionLd = {
    address: {
        addressCountry: `Canada`,
        addressLocality: 'Toronto',
        addressRegion: 'ON',
        postalCode: 'M5E1G4',
        streetAddress: '33 Yonge Street, Suite 810',
    },
    addressOneLine: '33 Yonge Street, Suite 810, Toronto, ON, M5E1G4',
    Brand: ['Recompenses Walmart', 'Walmart', 'Mastercard'],
    logo: imagePath('logo.svg', 'fr'),
    name: 'Banque Fairstone de Canada',
    telephone: '1-888-331-6133',
    URL: 'https://www.recompenseswalmart.ca',
};

export const frRewardsMCLoanOrCreditLd = {
    currency: 'CAD',
    description:
        'La carte de RécompensesMC Walmart MastercardMD est un carte de crédit sans frais annuels qui vous permet de cumuler des dollars de Récompenses à chaque fois que vous utilisez votre carte.',
    gracePeriod: '21 jours',
    image: imagePath('MC.png', 'fr'),
    interestRate: `En vigueur avant le 6 août 2024
    Pour les résidents hors du Québec:
    Carte à taux préférentiel
    20,89 %* - Taux d’intérêt.
    22,97 %* - Taux d’intérêt sur avance de fonds.
    
    Pour les résidents du Québec:
    Carte à taux préférentiel
    20,89 %* - Taux d’intérêt.
    21,97 %* - Taux d’intérêt sur avance de fonds.

    En vigueur le 6 août 2024
    Pour les résidents hors du Québec:
    Carte à taux préférentiel
    21,89 %* - Taux d’intérêt.
    22,97 %* - Taux d’intérêt sur avance de fonds.
    
    Pour les résidents du Québec:
    Carte à taux préférentiel
    21,89 %* - Taux d’intérêt.
    21,97 %* - Taux d’intérêt sur avance de fonds.

    Carte à taux standard‡
    25,99 % est le taux d’intérêt.
    27,99 % est le taux d’intérêt sur avances de fonds.
    
    Vous avez fait une demande pour obtenir notre carte à taux préférentiel. Si vous n’êtes pas approuvé pour la carte à taux préférentiel, vous reconnaissez que vous faites aussi une demande pour la carte à taux standard et vous autorisez que votre demande soit considérée pour les deux cartes.
    
    ‡Le taux standard n’est pas offert aux résidents du Québec et les demandes seront traitées seulement en relation à la carte taux préférentiel pour les résidents du Québec.
    `,
    loanType: 'Cart de credit',
    productName: `RécompensesMC Walmart MastercardMD`,
    termsOfService: documentPath('Online_Cardholder_Agreement_FR_VerT_AU24.pdf', 'fr'),
    url: 'https://recompenseswalmart.ca/apply',
};

export const frWorldMCLoanOrCreditLd = {
    currency: 'CAD',
    description:
        'La carte de RécompensesMC Walmart MastercardMD est un carte de crédit sans frais annuels qui vous permet de cumuler des dollars de Récompenses à chaque fois que vous utilisez votre carte.',
    gracePeriod: '21 jours',
    image: imagePath('MCWorld.png', 'fr'),
    interestRate: `En vigueur avant le 6 août 2024
    Pour les résidents hors du Québec:
    Carte à taux préférentiel
    20,89 %* - Taux d’intérêt.
    22,97 %* - Taux d’intérêt sur avance de fonds.
    
    Pour les résidents du Québec:
    Carte à taux préférentiel
    20,89 %* - Taux d’intérêt.
    21,97 %* - Taux d’intérêt sur avance de fonds.

    En vigueur le 6 août 2024
    Pour les résidents hors du Québec:
    Carte à taux préférentiel
    21,89 %* - Taux d’intérêt.
    22,97 %* - Taux d’intérêt sur avance de fonds.
    
    Pour les résidents du Québec:
    Carte à taux préférentiel
    21,89 %* - Taux d’intérêt.
    21,97 %* - Taux d’intérêt sur avance de fonds.
    
    Carte à taux standard‡
    25,99 % est le taux d’intérêt.
    27,99 % est le taux d’intérêt sur avances de fonds.
    
    Vous avez fait une demande pour obtenir notre carte à taux préférentiel. Si vous n’êtes pas approuvé pour la carte à taux préférentiel, vous reconnaissez que vous faites aussi une demande pour la carte à taux standard et vous autorisez que votre demande soit considérée pour les deux cartes.
    
    ‡Le taux standard n’est pas offert aux résidents du Québec et les demandes seront traitées seulement en relation à la carte taux préférentiel pour les résidents du Québec.
    `,
    loanType: 'Cart de credit',
    productName: `RécompensesMC Walmart MastercardMD`,
    termsOfService: documentPath('Online_Cardholder_Agreement_FR_VerT_AU24.pdf', 'fr'),
    url: 'https://recompenseswalmart.ca/apply',
};
